<!--
 * @Author: wxb
 * @Date: 2021-08-11 17:06:05
 * @LastEditTime: 2021-08-16 10:52:57
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\parkDetail.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/special'}">专题</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/park'}">园区专题</el-breadcrumb-item>
        <el-breadcrumb-item>园区详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="notice-panel"
           v-show="canSee">
        <div class="notice-title">{{parkDetail.hconglsyqmc}} </div>

        <div class="notice-detail">

          <table class="table table-border table-bordered">
            <tr>
              <td class="text-r notice-content-title"
                  width="150">园区类型</td>
              <td class="notice-content-info">{{parkDetail.hconglstypegardenname}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title"
                  width="150">园区面积</td>
              <td class="notice-content-info">{{parkDetail.hconglsqymj}} （km²）</td>
            </tr>
            <tr>
              <td class="text-r notice-content-title"
                  width="150">地址</td>
              <td class="notice-content-info">{{parkDetail.hconglsssqx1TEXT}} {{parkDetail.hconglsssqx2TEXT}}{{parkDetail.hconglsssqx3TEXT}}{{parkDetail.hconglsxxdz}}</td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">网址</td>
              <td class="notice-content-info">
                <el-link :underline="false"
                         type="primary"
                         :href="parkDetail.hconglswz"
                         target="_blank">{{parkDetail.hconglswz}}</el-link>
              </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">园区简介</td>
              <td class="notice-content-info">
                <div class="notice-panel-content"
                     v-html="parkDetail.hconglsjj"></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="file-panel"
           v-show="canSee">
        <div class="file-panel-title">园区荣誉</div>
        <div class="file-panel-content">
          <div class="project-search clearfix">
            <div class="project-search-input">
              <el-input class="project-search-field"
                        v-model="searchForm.keyword"
                        placeholder="输入关键词搜索..."
                        clearable
                        @keyup.enter.native="searchHonor"></el-input>
              <button type="button"
                      class="project-search-btn"
                      @click="searchHonor">搜索</button>
            </div>
          </div>
          <div class="exhibition-list">
            <el-table :data="resultLst4Honor"
                      stripe
                      border
                      style="width: 100%">
              <template slot="empty">
                <el-empty description="哎呀，未查询到相关信息"></el-empty>
              </template>
              <el-table-column prop="honorName"
                               label="奖项名称"
                               :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-link :href="scope.row.website"
                           target="_blank"
                           type="primary"
                           :title="scope.row.honorName">{{scope.row.honorName}}</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="issuingUnit"
                               label="颁发单位">
              </el-table-column>
              <el-table-column prop="issueTime"
                               label="颁发时间">
              </el-table-column>
            </el-table>
            <div class="page-panel">
              <el-pagination background
                             layout="prev, slot, next"
                             :total="honorListCount"
                             :current-page.sync="currentPage4Honor"
                             :hide-on-single-page="true"
                             @current-change="handleCurrentChange">
                <span class="current-page">{{currentPage4Honor}}</span>
              </el-pagination>
            </div>
          </div>

        </div>
      </div>
      <div class="file-panel"
           v-show="canSee">
        <div class="file-panel-title">园区企业</div>
        <div class="file-panel-content">
          <div class="project-search clearfix">
            <div class="project-search-input">
              <el-input class="project-search-field"
                        v-model="searchForm4company.keyword"
                        placeholder="输入关键词搜索..."
                        clearable
                        @keyup.enter.native="searchCompany"></el-input>
              <button type="button"
                      class="project-search-btn"
                      @click="searchCompany">搜索</button>
            </div>
          </div>
          <div class="exhibition-list">
            <el-table :data="resultLst4Company"
                      stripe
                      border
                      style="width: 100%">
              <template slot="empty">
                <el-empty description="哎呀，未查询到相关信息"></el-empty>
              </template>
              <el-table-column prop="enterpriseName"
                               label="企业名称"
                               :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.enterpriseId"
                             type="text"
                             size="small"
                             @click="handleClickCompany(scope.row.enterpriseId)">{{scope.row.enterpriseName}}</el-button>
                  <span v-else>{{scope.row.enterpriseName}}</span>
                </template>
              </el-table-column>

            </el-table>
            <div class="page-panel">
              <el-pagination background
                             layout="prev, slot, next"
                             :total="companyCount"
                             :current-page.sync="currentPage4Company"
                             :hide-on-single-page="true"
                             @current-change="handleCurrentChange4Company">
                <span class="current-page">{{currentPage4Company}}</span>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <viporlogin bgStr="5"
                :type="viporlogin"
                v-show="!canSee"></viporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { mapGetters } from 'vuex'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import { getParkDetails, getHonorDetailsList, getParkEnterpriseList } from '@/api/subject'
export default {
  name: 'parkDetail',
  components: {
    headerNav,
    footerNav,
    viporlogin
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      id: '',
      parkDetail: {},
      searchForm: {
        parkName: '',
        keyword: '',
        start: 0,
        limit: 10
      },
      resultLst4Honor: [{}],
      currentPage4Honor: 1,
      honorListCount: 0,
      searchForm4company: {
        parkId: '',
        keyword: '',
        start: 0,
        limit: 10
      },
      resultLst4Company: [{}],
      currentPage4Company: 1,
      companyCount: 0
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    authCheck() {
      const authObj = authenticate('parkDetail', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      // 加载详情
      getParkDetails({ id: this.id }).then(res => {
        if (res.ok) {
          const hconglsjj = res.info.hconglsjj || ''
          this.parkDetail = res.info
          this.parkDetail.hconglsjj = hconglsjj.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, '\'')
          this.searchForm.parkName = res.info.hconglsyqmc
          this.getHonor()
          this.searchForm4company.parkId = this.id
          this.getCompany()
        }
      }).catch(err => console.log(err))
    },
    // 加载荣誉
    getHonor() {
      getHonorDetailsList(this.searchForm).then(res => {
        if (res.ok) {
          this.resultLst4Honor = res.lst
          this.honorListCount = res.cnt
        }
      })
    },
    // 查询荣誉
    searchHonor() {
      this.searchForm.start = 0
      this.getHonor()
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getHonor()
    },
    // 加载企业
    getCompany() {
      getParkEnterpriseList(this.searchForm4company).then(res => {
        if (res.ok) {
          this.resultLst4Company = res.lst
          this.companyCount = res.cnt
        }
      })
    },
    // 查询企业
    searchCompany() {
      this.searchForm4company.start = 0
      this.getCompany()
    },
    // 分页
    handleCurrentChange4Company(val) {
      this.searchForm4company.start = (val - 1) * this.searchForm4company.limit
      this.getCompany()
    },
    // 跳转到企业详情
    handleClickCompany(id) {
      const routeData = this.$router.resolve({
        name: 'orgDetail',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.id = id
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.notice {
  &-panel {
    width: 144rem;
    margin: 0 auto;
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }

  &-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content-title {
    background: #f4f4ff;
  }
}

.file-panel {
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-height: 50rem;
  &-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    font-weight: 500;
    border-bottom: 1px solid #ebeef5;
    padding: 1.5rem;
  }

  &-content {
    padding: 1.5rem;
  }
}

// 搜索框
.project {
  &-search {
    background: #ffffff;
    margin-bottom: 15px;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      float: right;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;

      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.exhibition-list {
  /deep/ .el-table__empty-text {
    width: 100%;
  }
}

.page-panel {
  margin-top: 5px;
  text-align: right;
}
</style>
